import React from "react"
import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const TestPage = () => (
  <Layout>
    <SEO title="Kitchen Sink" />
    <StyledTestPage>
      <h1>Hello World</h1>
      <p>
        Lorem ipsum dolor sit amet <a href="#">consectetur</a> adipisicing elit.
        Deleniti dignissimos rem harum, incidunt nobis dolore quaerat commodi.
        Cumque perferendis excepturi est dolor natus minus ad consectetur
        consequatur reiciendis ipsum quaerat tempora nostrum a, maiores, modi
        expedita id nulla aliquam recusandae doloremque optio accusantium?
        Temporibus pariatur architecto quod, qui maiores ratione officia ipsa
        repellendus laboriosam. Quod quidem repellat consequuntur dolore
        similique sunt accusamus totam minima? Mollitia amet pariatur, ipsum
        odit odio earum? Tenetur unde itaque, velit dolore dolor aspernatur
        veritatis suscipit dignissimos quidem cumque. Odit obcaecati placeat
        repellendus sunt, ad autem nostrum quidem reprehenderit mollitia
        veritatis debitis fuga officia distinctio consequatur?
      </p>
      <button>Button</button>
      <hr />
      <h2>Hello World 2</h2>
      <h3>Hello</h3>
      <p>
        Lorem <strong>ipsum dolor</strong> sit amet consectetur adipisicing
        elit. Quae sit tempore ducimus libero, ea nesciunt dolore perferendis
        officiis dicta repellendus accusantium molestias odit nobis nemo et
        quidem nam rem omnis distinctio dolorum veniam quo sequi consectetur
        laudantium? Fugiat perspiciatis, doloremque odit laborum eaque illo,
        provident explicabo pariatur <a href="#">optio</a>, tempora nisi.
      </p>
      <h3>Lorem, ipsum.</h3>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis, qui!
      </p>
      <h2>Hello World</h2>
      <h3>Hello World</h3>
      <h4>Hello World</h4>
      <h5>Hello World</h5>
      <small>Hello Small</small>
      <hr />
      <ul>
        <li>Hello World</li>
        <li>Hello World</li>
        <li>Hello World</li>
      </ul>
    </StyledTestPage>
  </Layout>
)

const StyledTestPage = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

export default TestPage
